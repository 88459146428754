import axios from "axios";
import React, { useEffect, useState } from "react";
import { MARKETPLACE_ID } from "../config";
import { Link } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { PrintProductLabel, updateProduct } from "../actions/productActions";
import { PRODUCT_UPDATE_RESET } from "../constants/productConstants";
import Resizer from "react-image-file-resizer";
import { getStoreConfig } from "../actions/storeActions";
import env from "dotenv";
import VariantForm from "../components/variants/variant";
import VendorForm from "../components/vendors/vendors";

env.config();

const marketplaceId = MARKETPLACE_ID;

const ProductEditScreen = ({ match, history }) => {
  const productId = match.params.id;

  const [name, setName] = useState("");
  const [size, setSize] = useState("");
  const [price, setPrice] = useState(0);
  const [mrp, setMrp] = useState(0);
  const [cost, setCost] = useState(0);
  const [tax, setTax] = useState(0);
  const [expiryDate, setExpiryDate] = useState(new Date('2026-09-01T12:00:00'));
  const [profit, setProfit] = useState(0);
  const [images, setImages] = useState([]);
  const [sku, setSku] = useState("");
  const [brand, setBrand] = useState("");
  const [status, setStatus] = useState("");
  const [description, setDescription] = useState("");
  const [countInStock, setCountInStock] = useState(0);
  const [reorderLevel, setReorderLevel] = useState(0);
  const [labels, setlabels] = useState(0);
  const [category, setCategory] = useState("Kirana");
  const [subCategory, setSubCategory] = useState("");
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [vendors, setVendors] = useState([]);
  const [variants, setVariants] = useState([]);
  const [generateSkuChecked, setGenerateSkuChecked] = useState(false);




  const dispatch = useDispatch();

  const storeConfig = useSelector((state) => state.storeConfig);
  const { store } = storeConfig;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const categories = store.categories;

  console.log(categories);

  const productUpdate = useSelector((state) => state.productUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = productUpdate;

  useEffect(() => {
    dispatch(getStoreConfig(585412));
    if (successUpdate) {
      dispatch({ type: PRODUCT_UPDATE_RESET });
      history.push("/admin/productlist");
    }
    axios
      .get(`/api/marketplace/${marketplaceId}/products/${productId}`)
      .then((response) => {
        setLoading(false);
        setName(response.data.name === 'N' ? '' : response.data.name);
        setSize(response.data.size);
        setPrice((response.data.price === 1) ? '' : response.data.price);
        setMrp((response.data.mrp === 1) ? '' : response.data.mrp);
        setCost((response.data.cost === 1) ? '' : response.data.cost);
        setTax((response.data.tax === 1) ? '' : response.data.tax);
        setExpiryDate(new Date(response.data.expiryDate));
        setProfit(response.data.profit);
        setImages(response.data.images);
        setSku(response.data.sku === 'SYSTEM' ? '' : response.data.sku);
        setBrand(response.data.brand);
        setStatus(response.data.status);
        setDescription(response.data.description);
        setCategory(response.data.category);
        setSubCategory(response.data.subCategory);
        setCountInStock((response.data.countInStock === 1) ? '' : response.data.countInStock);
        setReorderLevel(response.data.reorderLevel === 0 ? '' : response.data.reorderLevel)
        setVendors(response.data.vendors)
        setVariants(response.data.variants)
      })
      .catch((error) => {
        setError(error);
      });
  }, [productId, successUpdate, dispatch, history]);

  const imagesToBeSent = (images.length) > 0 ? images : ["https://mufeed-images.s3.ap-south-1.amazonaws.com/bhendibhendi%20.webp"]

  const taxValue = (tax * 100) / price

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateProduct({
        _id: productId,
        name,
        size,
        price,
        mrp,
        cost,
        expiryDate,
        tax: taxValue,
        profit,
        images: imagesToBeSent,
        sku,
        brand,
        status,
        category,
        subCategory,
        description,
        countInStock,
        reorderLevel,
        vendors,
        variants
      })
    );
  };

  const printHandler = () => {

    dispatch(
      PrintProductLabel({
        _id: productId,
        name,
        size,
        price,
        mrp,
        tax,
        profit,
        images: imagesToBeSent,
        sku,
        brand,
        status,
        category,
        subCategory,
        description,
        countInStock,
        labels
      })
    );
  };

  const handleVariantChange = (event, index) => {
    const { name, value } = event.target;
    setVariants((prevVariants) => {
      const updatedVariants = [...prevVariants];
      updatedVariants[index][name] = value;
      return updatedVariants;
    });
  };

  const addVariant = () => {
    setVariants((prevVariants) => [
      ...prevVariants,
      { name: '', price: '', mrp: '', color: '', size: '' },
    ]);
  };

  const removeVariant = (index) => {
    setVariants((prevVariants) => {
      const updatedVariants = [...prevVariants];
      updatedVariants.splice(index, 1);
      return updatedVariants;
    });
  };


  const handleVendorChange = (index, field, value) => {
    setVendors((prevVendors) => {
      const updatedVendors = [...prevVendors];
      if (field === "cost") {
        updatedVendors[index].cost = value;
      } else {
        updatedVendors[index].vendor[field] = value;
      }
      return updatedVendors;
    });
  };
  const addNewVendor = () => {
    setVendors((prevVendors) => [
      ...prevVendors,
      { vendor: { name: "", email: "", phone: "" }, cost: 0 },
    ]);
  };

  const uploadFileHandler = async (e) => {
    const formData = new FormData();
    const files = e.target.files;
    if (files) {
      setUploading(true);
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        Resizer.imageFileResizer(
          file,
          750,
          750,
          "JPEG",
          400,
          0,
          (blob) => {
            formData.append("images", blob, name + file.name);
            axios
              .post("/api/upload", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((response) => {
                setUploading(false);
                const updateResponse = response.data.split(",");
                setImages(updateResponse);
              })
              .catch((err) => {
                console.log(err);
                setUploading(false);
              });
          },
          "blob"
        );
      }
    }
  };
  const handleDateChange = (date) => {
    setExpiryDate(date);
  };
  const handleCheckboxChange = (e) => {
    setGenerateSkuChecked(e.target.checked);
    if (e.target.checked) {
      generateSku();
    } else {
      setSku(''); // Clear the SKU if unchecked
    }
  };
  const generateSku = () => {
    axios.get(`https://www.mufeed.co.in/api/config/${userInfo.store}/sku`).then(function (response) {
      setSku(response.data.sku)
    }).catch(function (error) {
      console.log('error', error);
    });

  };

  return (
    <div style={{ background: '#EFF1EE', marginLeft: '-5em', marginTop: '-5em', marginRight: '-4em', paddingLeft: "3em", paddingTop: '5em', height: '100ßem' }}>
      <Link to="/admin/productlist" className="btn btn-secondary my-3">
        {" "}
        Go back
      </Link>
      <div className="mb-4">
        <h3>Edit product</h3> {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="warning">{errorUpdate}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="warning">{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Row>
              <Col xs={12} sm={6}>
                <Form.Group className="mb-2" controlId="name">
                  <Form.Label className="form-label-custom">Name </Form.Label>
                  <Form.Control
                    type="name"
                    placeholder="Product Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="mb-2" controlId="size">
                  <Form.Label className="form-label-custom">Size</Form.Label>
                  <Form.Control
                    type="name"
                    placeholder="Enter number in g, kg, litre, ml"
                    value={size}
                    onChange={(e) => setSize(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="mb-2" controlId="price">
                  <Form.Label className="form-label-custom">Selling price</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Price"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="mb-2" controlId="mrp">
                  <Form.Label className="form-label-custom">MRP</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Price"
                    value={mrp}
                    onChange={(e) => setMrp(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="mb-2" controlId="cost">
                  <Form.Label className="form-label-custom">Purchasing cost</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Cost"
                    value={cost}
                    onChange={(e) => setCost(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="mb-2" controlId="expiry-date">
                  <Form.Label className="form-label-custom">Expiry date </Form.Label>
                  <div className="mt-2" > {/* Wrap the DatePicker in a div */}
                    <DatePicker
                      selected={expiryDate}
                      onChange={handleDateChange}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Enter expiry date"
                      className="form-control"
                    />
                  </div>
                </Form.Group>̦̦
                {userInfo.role === "superAdmin" ||
                  userInfo.role === "deliveryAdmin" ? (
                  <Form.Group className="mb-2" controlId="profit">
                    <Form.Label className="form-label-custom">Profit</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter Profit"
                      value={profit}
                      onChange={(e) => setProfit(e.target.value)}
                    ></Form.Control>{" "}
                  </Form.Group>
                ) : null}
                <Form.Group className="mb-2" controlId="countInStock">
                  <Form.Label className="form-label-custom">Stock</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Count In Stock"
                    value={countInStock}
                    onChange={(e) => setCountInStock(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="mb-2" controlId="reorderlevel">
                  <Form.Label className="form-label-custom">Reorder level</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter reorder level"
                    value={reorderLevel}
                    onChange={(e) => setReorderLevel(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="mb-2" controlId="sku">
                  <Form.Label className="form-label-custom">SKU</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter SKU"
                    value={sku}
                    onChange={(e) => setSku(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="mb-2" controlId="generateSku">
                  <Form.Check
                    type="checkbox"
                    label="Generate SKU Automatically"
                    checked={generateSkuChecked}
                    onChange={handleCheckboxChange}
                  />
                </Form.Group>
                <Form.Group className="mb-2" controlId="labels">
                  <Form.Label className="form-label-custom">Number of labels</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter number of labels required"
                    value={labels}
                    onChange={(e) => setlabels(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="d-grid gap-2 mt-2 mb-5">
                  <Button variant="secondary" size="lg" onClick={printHandler}>
                    Print label
                  </Button>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-2" controlId="images">
                  <Form.Label className="form-label-custom">Images</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Image URL "
                    value={images}
                    onChange={(e) => setImages(e.target.value)}
                  ></Form.Control>
                  <Form.Label className="btn btn-secondary">
                    Choose File
                    <Form.File
                      id="image-file"
                      multiple
                      hidden
                      custom
                      onChange={uploadFileHandler}
                    ></Form.File>
                  </Form.Label>
                  {uploading && <Loader />}
                </Form.Group>
                <Form.Group className="mb-2" controlId="brand">
                  <Form.Label className="form-label-custom">Brand</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Brand Name"
                    value={brand}
                    onChange={(e) => setBrand(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group className="mb-2" controlId="status">
                  <Form.Label className="form-label-custom">Status</Form.Label>
                  <select
                    className="form-control"
                    type="form-select"
                    placeholder="Enter Status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value="In Review"> In review</option>
                    <option value="Published">Published</option>
                  </select>
                </Form.Group>

                <Form.Group className="mb-2" controlId="category">
                  <Form.Label>Category</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Category "
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    as="select"
                  >
                    {categories &&
                      categories.map((allCategory) => (
                        <option key={allCategory.id} value={allCategory.name}>
                          {allCategory.name}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group className="mb-2" controlId="subCategory">
                  <Form.Label>Sub Category</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Sub Category "
                    value={subCategory}
                    onChange={(e) => setSubCategory(e.target.value)}
                    as="select"
                  >
                    {categories &&
                      categories
                        .filter((subCategory) => subCategory.name === category)
                        .map((category) => {
                          console.log(category.name);
                          console.log(category.subCategories);
                          return category.subCategories.map((subCategory) => (
                            <option
                              key={subCategory.id}
                              value={subCategory.name}
                            >
                              {" "}
                              {subCategory.name}
                            </option>
                          ));
                        })}
                  </Form.Control>
                </Form.Group>
                <Form.Group className="mb-2" controlId="description">
                  <Form.Label className="form-label-custom">Description</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Description "
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group className="d-grid gap-2 mt-2 mb-5">
                  <Button variant="secondary" size="lg" type="submit">
                    Update
                  </Button>
                </Form.Group>
              </Col>
            </Row>

            <div className="my-5">
              <VariantForm
                variants={variants}
                handleVariantChange={handleVariantChange}
                removeVariant={removeVariant}
                addVariant={addVariant}
              />
            </div>

            <div className="my-5">
              <VendorForm vendors={vendors} addNewVendor={addNewVendor} handleVendorChange={handleVendorChange}></VendorForm>
            </div>

          </Form>
        )}
      </div>
    </div>
  );
};

export default ProductEditScreen;
