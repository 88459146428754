// VariantForm.js

import React from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import './variant.css'; // Import the CSS file

function VariantForm({ variants, handleVariantChange, removeVariant, addVariant }) {
    return (
        <div>
            {variants.map((variant, index) => (
                <Row key={index} className="variant-row p-3 mb-3 border rounded">
                    <Col xs={12}>
                        <Row>
                            <Col xs={12} md={4}>
                                <Form.Group controlId={`formVariantName-${index}`} className="mb-2">
                                    <Form.Label className="form-label-custom">Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        value={variant.name}
                                        onChange={(e) => handleVariantChange(e, index)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4}>
                                <Form.Group controlId={`formVariantSku-${index}`} className="mb-2">
                                    <Form.Label className="form-label-custom">SKU</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="sku"
                                        value={variant.sku}
                                        onChange={(e) => handleVariantChange(e, index)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4}>
                                <Form.Group controlId={`formVariantPrice-${index}`} className="mb-2">
                                    <Form.Label className="form-label-custom">Selling rice</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="price"
                                        value={variant.price}
                                        onChange={(e) => handleVariantChange(e, index)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={4}>
                                <Form.Group controlId={`formVariantMrp-${index}`} className="mb-2">
                                    <Form.Label className="form-label-custom">MRP</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="mrp"
                                        value={variant.mrp}
                                        onChange={(e) => handleVariantChange(e, index)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4}>
                                <Form.Group controlId={`formVariantColor-${index}`} className="mb-2">
                                    <Form.Label className="form-label-custom">Color</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="color"
                                        value={variant.color}
                                        onChange={(e) => handleVariantChange(e, index)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4}>
                                <Form.Group controlId={`formVariantSize-${index}`} className="mb-2">
                                    <Form.Label className="form-label-custom">Size</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="size"
                                        value={variant.size}
                                        onChange={(e) => handleVariantChange(e, index)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={4}>
                                <Form.Group controlId={`formVariantImage-${index}`} className="mb-2">
                                    <Form.Label className="form-label-custom">Image URL</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="image"
                                        value={variant.image}
                                        onChange={(e) => handleVariantChange(e, index)}
                                    />
                                </Form.Group>
                            </Col>

                        </Row>
                        <Col xs={12} md={4}>
                            <Button
                                variant="outline-danger"
                                className="mt-3 remove-button"
                                onClick={() => removeVariant(index)}
                                disabled={variants.length === 1}
                            >
                                Remove
                            </Button>
                        </Col>
                    </Col>
                </Row>
            ))}
            <Button variant="primary" onClick={addVariant} className="mt-3">
                Add variant
            </Button>
        </div>
    );
}

export default VariantForm;