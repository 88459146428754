import React from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import './vendor.css'

function VendorForm({ vendors, handleVendorChange, addNewVendor }) {
    return (
        <div>
            {vendors?.map((vendorData, index) => (
                <Form key={index} className="vendor-form mb-3 p-3 border rounded"> {/* Added vendor-form class */}
                    <Row>
                        <Col xs={12} md={4}>
                            <Form.Group controlId={`vendorName${index}`} className="mb-2">
                                <Form.Label className="form-label-custom">Name</Form.Label> {/* Added form-label-custom class */}
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Vendor Name"
                                    value={vendorData.vendor.name}
                                    disabled={vendorData.vendor._id}
                                    onChange={(e) =>
                                        handleVendorChange(index, "name", e.target.value)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Group controlId={`vendorEmail${index}`} className="mb-2">
                                <Form.Label className="form-label-custom">Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Enter Vendor Email"
                                    value={vendorData.vendor.email}
                                    disabled={vendorData.vendor._id}
                                    onChange={(e) =>
                                        handleVendorChange(index, "email", e.target.value)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Group controlId={`vendorPhone${index}`} className="mb-2">
                                <Form.Label className="form-label-custom">Phone</Form.Label>
                                <Form.Control
                                    type="tel"
                                    placeholder="Enter Vendor Phone"
                                    value={vendorData.vendor.phone}
                                    disabled={vendorData.vendor._id}
                                    onChange={(e) =>
                                        handleVendorChange(index, "phone", e.target.value)
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={4}>
                            <Form.Group controlId={`vendorCost${index}`} className="mb-2">
                                <Form.Label className="form-label-custom">Cost</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Enter Vendor Cost"
                                    value={vendorData.cost}
                                    onChange={(e) =>
                                        handleVendorChange(index, "cost", e.target.value)
                                    }
                                />
                            </Form.Group>
                        </Col>
                        {/* You can add more fields here if needed */}
                    </Row>
                </Form>
            ))}
            <Button variant="primary" className="mt-2" onClick={addNewVendor}>
                Add vendor
            </Button>
        </div>
    );
}

export default VendorForm;