import React, { useEffect, useState } from 'react';
import { Col, Row, Form, Button, Table } from 'react-bootstrap';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { finishing } from '../actions/productActions';
import { addToCart } from '../actions/cartActions'; // Import the new action
import { useDispatch, useSelector } from 'react-redux';

export const LessTenProducts = ({ history }) => {
  const dispatch = useDispatch();
  const finishingSt = useSelector((state) => state.finishingStocks);
  const { finishingStocks, loading, error } = finishingSt;
  const storeConfig = useSelector((state) => state.storeConfig);
  const { store } = storeConfig;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const categories = store?.categories || []; // Safe access

  const [category, setCategory] = useState(''); // Allow no category
  const [subCategory, setSubCategory] = useState(''); // Allow no subcategory
  const [count, setCount] = useState(5); // Default count is 5

  useEffect(() => {
    if (userInfo && userInfo.role.toLowerCase().includes("admin")) {
      dispatch(finishing(category, subCategory, count)); // Load with initial filters
    } else {
      history.push('/login');
    }
  }, [dispatch, history, userInfo, category, subCategory, count]);

  const handleAddToCartAll = () => {
    if (finishingStocks && finishingStocks.length > 0) {
      finishingStocks.forEach((stock) => {
        dispatch(
          addToCart(
            stock._id,
            1, // Assuming default quantity is 1, adjust as needed
          )
        );
      });
    }
  };

  return (
    <>
      <Row className="mb-3"> {/* Added a Row for better layout */}
        <Col>
          <p className="DashboardHeading">Stock Alert</p>
        </Col>
      </Row>

      <Form>
        <Row className="mb-3">
          <Col md={3}>
            <Form.Group className="mb-2" controlId="category">
              <Form.Label>Category</Form.Label>
              <Form.Control
                type="text"
                placeholder="Select Category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                as="select"
              >
                <option value="">All Categories</option>
                {categories.map((allCategory) => (
                  <option key={allCategory.id} value={allCategory.name}>
                    {allCategory.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group className="mb-2" controlId="subCategory">
              <Form.Label>Sub Category</Form.Label>
              <Form.Control
                type="text"
                placeholder="Select Sub Category"
                value={subCategory}
                onChange={(e) => setSubCategory(e.target.value)}
                as="select"
              >
                <option value="">All Subcategories</option>
                {category && categories
                  .find((cat) => cat.name === category)?.subCategories
                  .map((sub) => (
                    <option key={sub.id} value={sub.name}>
                      {sub.name}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group controlId="count">
              <Form.Label>Count Limit</Form.Label>
              <Form.Control
                type="number"
                value={count}
                onChange={(e) => setCount(parseInt(e.target.value))}
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>

      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="warning">{error}</Message>
      ) : (
        <>
          <Table striped bordered hover responsive className="mt-3">
            <thead>
              <tr>
                <th >Product name</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {finishingStocks.map((stock) => (
                <tr
                  key={stock._id}
                >
                  <td>{stock.name || stock._id}</td>
                  <td>{stock.countInStock}</td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Row className="mt-3">
            <Col>
              <Button onClick={handleAddToCartAll} variant="primary">
                Add all To Cart
              </Button>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};